<template>
  <v-container fluid>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="back">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <img height="40" width="40" src="@/assets/truffle_logo.png" />
      <v-toolbar-title class="headline font-weight-bold">{{ serviceName }}</v-toolbar-title>
    </v-app-bar>
    <v-card>
      <v-card-title>募集者リスト</v-card-title>
      <v-card-text v-if="applicants.length==0">
        <v-alert outlined type="info">現在、応募者はおりません</v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-card outlined v-for="a in applicants" :key="a.applicantName.concat(a.applicantEmail)">
          <v-card-title>{{a.applicantName}}</v-card-title>
          <v-card-text>
            <dl>
              <dt>連絡先</dt>
              <dd>- {{a.applicantEmail}}</dd>
              <dt>応募職種</dt>
              <dd>- {{a.objective}}</dd>
              <dt>飲み物の希望</dt>
              <dd>- {{a.desiredDrink}}</dd>
            </dl>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { serviceName } from '@/truffle.config'
import { getAllApplicantData } from '@/helpers/firebase-functions'
import { mapState } from 'vuex'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  data: () => ({
    serviceName,
    applicants: []
  }),
  computed: {
    ...mapState('auth', ['uid'])
  },
  methods: {
    back () {
      this.$router.back()
    }
  },
  async created () {
    const ownerId = this.uid
    const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    const staffId = this.$route.params.staffId
    const { data } = await getAllApplicantData({ ownerId, ownerScenarioId, staffId })
    this.applicants = data.sort((a, b) => {
      if (a.applicantName < b.applicantName) return -1
      else if (a.applicantName === b.applicantName) return 0
      else return 1
    })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_REFERRER_DETAILS)
  }
}
</script>
